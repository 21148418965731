[data-component="Select"] {
  position: relative;
  flex-shrink: 0;
  &.error {
    textarea, input {
      border-color: var(--red-text) !important;
    }
  }
  .heading {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    margin: 0px 1px;
    padding: 0px var(--unit-sm);
    display: flex;
    align-items: flex-end;
    height: var(--unit-xl);
  }
  .error-text {
    margin: 0px 1px;
    padding: 0px var(--unit-lg);
    font-size: var(--font-size-sm);
    color: var(--red-text);
  }
  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    padding: 0px var(--unit-lg);
    margin-right: calc(var(--unit-lg) * 1.5);
    font-family: var(--font-family);
    background-color: var(--background-primary);
    color: var(--color-primary);
    font-size: var(--font-size-md);
    line-height: calc(var(--form-md) - 2px);
    min-height: var(--form-md);
    border-radius: var(--radius-lg);
    background: var(--background-input);
    border: 1px solid transparent;
    caret-color: var(--color-tertiary);
    outline: none;
    resize: none;
    animation:
      border-color 0.1s ease-in-out;
    &:hover {
      border-color: var(--outline-minimal);
    }
    &:focus {
      border-color: var(--outline-default);
      outline: none;
    }
    &:disabled {
      cursor: default;
      color: var(--color-disabled);
      &:hover {
        border-color: var(--outline-default);
      }
    }
  }
  .select-wrapper {
    position: relative;
  }
  .select-wrapper:after {
    content: '';
    position: absolute;
    top: 50%;
    right: calc(var(--unit-md) * 2);
    width: 0px;
    height: 0px;
    border: 3px solid transparent;
    border-right-color: var(--color-disabled);
    border-bottom-color: var(--color-disabled);
    transform: translateY(-70%) rotate(45deg);
    pointer-events: none;
  }
  .select-wrapper.readonly:after {
    opacity: 0.3;
  }
  &[data-size="small"] {
    select {
      font-size: var(--font-size-sm);
      line-height: calc(var(--form-sm) - 2px);
      min-height: var(--form-sm);
      padding: 0px var(--unit-md);
    }
    .select-wrapper:after {
      font-size: var(--font-size-xs);
      right: calc(var(--unit-md) + var(--unit-sm));
    }
  }
}