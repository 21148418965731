[data-component="PackageCodePage"] {

  position: absolute;
  display: flex;
  flex-grow: 1;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: relative;

}