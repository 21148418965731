[data-component="CreatePackageModal"] {
  
  .create-package-title {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-medium);
    margin-bottom: var(--unit-md);
    padding: 0px var(--unit-md);
    &:not(:first-child) {
      margin-top: var(--unit-lg);
    }
  }

  .package-name {
    display: flex;
    flex-direction: row;
    gap: var(--unit-md);
    align-items: flex-start;
    .package-organization-name {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      padding-left: var(--unit-md);
      color: var(--color-tertiary);
      height: var(--form-md);
    }
  }

}