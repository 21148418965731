[data-component="MiniEditor"] {
  display: flex;
  width: 100%;
  flex-direction: column;
  font-family: var(--font-family);
  font-size: var(--font-size-sm);
  color: var(--color-tertiary);
  .mini-editor-header {
    display: flex;
    align-items: center;
    padding: var(--unit-sm);
    background: var(--background-tertiary);
    border-radius: var(--radius-lg) var(--radius-lg) 0 0;
    flex-wrap: wrap;
    justify-content: flex-end;
    & > svg {
      width: calc(var(--unit-md) + var(--unit-sm));
      height: calc(var(--unit-md) + var(--unit-sm));
      margin: 0px var(--unit-sm);
    }
    & > span {
      padding: 0px var(--unit-sm);
    }
    & + .mini-editor-content control[control="CPHEditor"] {
      border-radius: 0 0 var(--radius-lg) var(--radius-lg);
      border-color: var(--background-tertiary) !important;
    }
    .actions {
      display: flex;
      align-items: center;
      gap: var(--unit-sm);
      overflow: visible;
    }
  }
  .mini-editor-content {
    position: relative;
    width: 100%;
    height: 100%;
  }
  &[data-type="error"] {
    .mini-editor-header svg.start-icon {
      color: var(--red-main);
      stroke-width: 2;
    }
  }
  &[data-type="success"] {
    .mini-editor-header svg.start-icon {
      color: var(--green-light);
      stroke-width: 2;
    }
  }
  &[data-display="package"] {
    background: transparent;
    .mini-editor-header {
      color: var(--color-primary);
      font-size: var(--font-size-md);
      background: transparent;
      margin-top: var(--unit-xl);
      padding: var(--unit-lg);
      display: flex;
      align-items: center;
      gap: var(--unit-md);
      font-weight: var(--font-weight-medium);
      .actions {
        height: 16px; // set height to prevent buttons from oversizing
      }
      & > span {
        padding: var(--unit-sm) 0px;
      }
      & > span.label {
        font-weight: var(--font-weight-normal);
        color: var(--color-tertiary);
      }
      & > svg {
        width: calc(var(--unit-md) + var(--unit-sm));
        height: calc(var(--unit-md) + var(--unit-sm));
        stroke-width: 1;
        opacity: 0.6;
      }
      a {
        display: inline-flex;
        align-items: center;
        gap: var(--unit-sm);
        svg {
          width: calc(var(--unit-md) + var(--unit-sm));
          height: calc(var(--unit-md) + var(--unit-sm));
          stroke-width: 1;
        }
      }
    }
    .mini-editor-content {
      control[control="CPHEditor"] {
        border: none;
        border-radius: var(--radius-lg);
      }
    }
  }
  &[data-display="embed"] {
    background: transparent;
    .mini-editor-header {
      background: transparent;
      padding: var(--unit-sm) 0px;
      color: var(--color-disabled);
      & > span {
        padding: 0px;
      }
    }
    .mini-editor-content {
      max-height: 128px;
      overflow: auto;
      border-radius: var(--radius-lg);
      control[control="CPHEditor"] {
        border: none;
        border-radius: var(--radius-lg);
      }
    }
  }
}