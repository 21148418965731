[data-component="BillingMethodsPage"] {

  position: relative;
  max-width: var(--screen-md);
  width: 100%;
  padding: var(--unit-xl) calc(var(--unit-xl) * 2);

  @media screen and (max-width:567px) {
    padding: var(--unit-xl) var(--unit-lg);  
  }

  .billing-methods {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: var(--unit-lg);
    margin-top: var(--unit-lg);
  }
}

[data-component="PaymentMethodCard"] {
  background: var(--background-input);
  border: 1px solid var(--outline-default);
  border-radius: var(--radius-xl);
  padding: var(--unit-lg);
  aspect-ratio: 5/3;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.default {
    border-color: var(--outline-hover);
    background: var(--background-secondary);
  }

  .card-actions,
  .card-billing-details {
    display: flex;
    gap: var(--unit-md);
    justify-content: space-between;
  }

  .card-info {
    font-family: 'Courier New', Courier, monospace;
  }

  .card-number {
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-medium);
    padding: var(--unit-sm) 0;
  }

  .card-expiry {
    span {
      opacity: 0.5;
    }
  }
}