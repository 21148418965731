[data-component="LoadingContentDiv"] {
  display: flex;
  width: 300px;
  max-width: 100%;
  height: var(--form-sm);
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: var(--unit-md);
    left: var(--unit-md);
    right: var(--unit-md);
    bottom: 0px;
    background: linear-gradient(90deg, var(--background-input) 0%, var(--background-secondary) 50%, var(--background-input) 100%);
    background-size: 200% 100%;
    border-radius: var(--radius);
    animation: loading-shimmer 1.5s infinite linear;
  }
}

@keyframes loading-shimmer {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}