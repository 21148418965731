[data-component="AgentsChat"] {

  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;

  .main-content {

    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    
    .central-content {
      flex-grow: 1;
      position: relative;
      overflow: auto;
    }

  }

  &:not([data-is-profile-open="true"]) {
    .right-sidebar {
      width: 0px !important;
    }
  }

  .right-sidebar {

    &[data-is-tablet="true"] {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 999;
      width: 100%;
      border-left: none;
      .right-sidebar-container {
        width: 100%;
      }
    }

    position: relative;
    flex-shrink: 0;
    flex-grow: 0;
    overflow: hidden;
    border-left: 1px solid var(--outline-default);
    width: 400px;
    max-width: calc(100vw);
    transition: width 0.1s ease-in-out,
      border-color 0.1s ease-in-out;

    .right-sidebar-container {
      position: relative;
      width: 400px;
      max-width: calc(100vw);
      height: 100%;
      overflow: auto;
    }

    .close-right-sidebar {
      position: absolute;
      top: var(--unit-lg);
      right: var(--unit-lg);
    }

  }

}