[data-component="PackagesPage"] {

  padding: var(--unit-md) var(--unit-lg);
  margin-bottom: calc(var(--unit-xl) * 2);

  .packages-title {
    font-size: var(--font-size-xxl);
    font-weight: var(--font-weight-medium);
    margin-bottom: calc(var(--unit-lg) * 3);
    text-align: center;

    .subheading {
      font-weight: normal;
      font-size: var(--font-size-lg);
      margin-top: var(--unit-lg);
      color: var(--color-secondary);
    }
  }

  .packages-actions {
    max-width: var(--screen-sm);
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    gap: var(--unit-md);
    .packages-actions-row {
      display: flex;
      flex-direction: row;
      gap: var(--unit-md);
      flex-wrap: wrap-reverse;
      justify-content: flex-end;
    }
  }

  .packages-list {
    max-width: var(--screen-sm);
    margin: 0px auto;
    & > [data-component] {
      margin: var(--unit-md) 0px;
    }
    .packages-list-empty {
      text-align: center;
      margin-top: var(--unit-md);
      color: var(--color-disabled);
      background: var(--background-input);
      border-radius: var(--radius-lg);
      padding: var(--unit-xl);
    }
  }

}