[data-component="DashboardPage"] {

  height: calc(100% - var(--nav-height));
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
  background: var(--background-primary);

  &:not([data-menu-open="true"]) {
    .left-sidebar-container {
      width: 0px !important;
      max-width: 0px !important;
    }
    .chat-message > table {
      pointer-events: none; // Hack for scrolling on tables in chat
    }
  }

  .left-sidebar-container {
    width: 300px;
    max-width: 300px;
    flex-shrink: 0;
    position: relative;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    transition: width 0.1s ease-in-out, max-width 0.1s ease-in-out;
    &.dragging {
      transition: none;  
    }
  }

  .left-sidebar {
    width: 300px;
    max-width: 300px;
    position: relative;
    flex-shrink: 0;
    flex-grow: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: var(--background-secondary);
    .sidebar-header {
      display: flex;
      align-items: center;
      position: relative;
      flex-shrink: 0;
      padding: var(--unit-md);
      padding-right: var(--unit-md);
      padding-bottom: var(--unit-sm);
      gap: var(--unit-sm);
      flex-shrink: 0;
      .sidebar-logo {
        border-radius: var(--radius-lg);
        height: var(--form-md);
        padding: 0px;
        padding-left: var(--unit-lg);
        display: flex;
        align-items: center;
        width: 100%;
        display: flex;
        a {
          display: flex;
        }
        img {
          height: var(--form-sm);
        }
        &:hover {
          background: var(--background-tertiary);
        }
        &.active {
          background: var(--blue-dark);
        }
      }
    }

    .sidebar-agents,
    .sidebar-packages {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      padding: var(--unit-sm) var(--unit-md);
      position: relative;
      overflow: hidden;
      &:before {
        display: block;
        position: absolute;
        top: 0px;
        left: var(--unit-md);
        right: var(--unit-md);
        height: 1px;
        content: '';
        background: var(--outline-minimal);
      }
      &.active {
        .sidebar-agents-title,
        .sidebar-packages-title {
          cursor: default !important;
          background: transparent;
          * {
            cursor: default !important;
          }
        }
      }
      .sidebar-agents-title,
      .sidebar-packages-title {
        display: flex;
        height: var(--form-md);
        width: 100%;
        align-items: center;
        padding: 0px var(--unit-lg);
        padding-right: 0px;
        border-radius: var(--radius-lg);
        text-decoration: none;
        color: var(--color-primary) !important;
        gap: var(--unit-lg);
        & > .icon {
          display: flex;
          flex-shrink: 0;
          align-items: center;
          margin: 0px var(--unit-md);
          width: var(--unit-lg);
          height: var(--unit-lg);
          opacity: 0.4;
        }
        &:hover {
          background: var(--background-tertiary);
        }
        &.active {
          background: var(--blue-dark);
          & > .icon {
            opacity: 0.6;
          }
        }
      }
    }

    .sidebar-agents-list-container,
    .sidebar-packages-list-container {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: hidden;
      transition: max-height 0.1s ease-in-out;
      .sidebar-search {
        flex-shrink: 0;
        padding: 0px var(--unit-md);
        padding-top: var(--unit-sm);
      }
      .sidebar-agents-list,
      .sidebar-packages-list {
        overflow: auto;
        padding: var(--unit-md) 0px;
      }
      .sidebar-agents-actions,
      .sidebar-packages-actions {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: var(--unit-md) 0px;
        padding-right: var(--unit-md);
      }
    }

    .sidebar-user {
      flex-shrink: 0;
      height: calc(var(--form-md) + var(--unit-sm) * 2);
      margin-bottom: var(--unit-sm);
      position: relative;
      &:before {
        display: block;
        position: absolute;
        top: 0px;
        left: var(--unit-md);
        right: var(--unit-md);
        height: 1px;
        content: '';
        background: var(--outline-minimal);
      }
    }

    .sidebar-user-container,
    .sidebar-home-container {
      position: relative;
      flex-grow: 1;
      overflow: auto;
      transition: max-height 0.1s ease-in-out;
      .sidebar-menu {
        padding: var(--unit-md) 0px;
        padding-top: 0px;
        min-height: 100%;
        display: flex;
        flex-direction: column;

        .menu-spacer {
          flex-grow: 1;
          min-height: var(--unit-sm);
        }

        .menu {
          flex-shrink: 0;
          position: relative;
          overflow: hidden;
          padding: 0px;
          margin: 0px var(--unit-md);
          display: flex;
          flex-direction: column;
          gap: var(--unit-sm);
          hr {
            width: 100%;
            height: 1px;
            border: none;
            border-top: 1px solid var(--outline-minimal);
            background: transparent;
            margin: var(--unit-sm) 0px;
            margin-left: calc(var(--unit-lg) * 4);
          }
          li {
            display: flex;
            position: relative;
            width: 100%;
            & > span,
            & > a {
              border-radius: var(--radius-lg);
              display: flex;
              padding: var(--unit-md);
              padding-right: 0px;
              flex-grow: 1;
              align-items: center;
              gap: var(--unit-md);
              color: var(--color-primary);
              & > .icon {
                width: 16px;
                height: 16px;
                margin: 0px var(--unit-lg);
                opacity: 0.4;
                position: relative;
                &.fill {
                  path {
                    fill: currentColor;
                  }
                }
              }
              &.center {
                justify-content: center;
              }
              .regular {
                color: var(--color-primary);
                font-weight: var(--font-weight-normal);
              }
            }
            & > a:hover {
              background: var(--background-tertiary);
              text-decoration: none;
            }
            & > a.active {
              background: var(--blue-dark);
              color: var(--color-primary);
              .icon {
                color: var(--color-primary);
                opacity: 0.6;
              }
            }
          }
        }
      }
    }

  }

  .agents-actions {
    display: flex;
    width: 100%;
    padding: var(--unit-lg);
    align-items: center;
    justify-content: center;
  }

  .dashboard-content {
    position: relative;
    z-index: 1;
    flex-grow: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    & > [data-component$="Page"] {
      flex-grow: 1;
    }
    &:before {
      position: absolute;
      content: '';
      opacity: 0;
      z-index: 9998;
      width: 100%;
      height: 100000px;
      transform: translate3d(0,0,0);
      backdrop-filter: blur(2px) grayscale(0.5);
      -webkit-backdrop-filter: blur(2px) grayscale(0.5);
      display: none;
    }
    &:after {
      position: absolute;
      content: '';
      opacity: 0;
      z-index: 9997;
      width: 100%;
      height: 100000px;
      background: var(--background-primary);
      display: none;
    }
    &[data-is-blurred="true"] {
      overflow: hidden;
      &:before, &:after {
        display: block;
      }
      &:before {
        opacity: 1.0;
      }
      &:after {
        opacity: 0.4;
      }
    }
  }

  &[data-is-mobile="true"] {
    overflow: hidden;
    .left-sidebar-container,
    .left-sidebar {
      width: calc(100vw - var(--form-md) - var(--unit-lg));
      max-width: 300px;
    }
    .dashboard-content {
      min-width: 100vw;
    }
    // On small mobile screens, make sidebar full width
    @media (max-width: 500px) {
      .left-sidebar-container,
      .left-sidebar {
        width: 100vw;
        max-width: 100vw;
      }
    }
  }

}