[data-component="Toggle"] {
  position: relative;
  max-width: 100%;
  flex-shrink: 0;
  display: inline-flex;
  user-select: none;

  &.no-icon {
    button {
      .prefix {
        display: none;
      }
      .postfix {
        display: none;
      }
      .label {
        padding-left: var(--unit-lg);
        margin-left: 0px !important;
        margin-right: 0px !important;
      }
    }
    &[data-size="small"] {
      button {
        .label {
          padding-left: var(--unit-md);
          margin-left: 0px !important;
          margin-right: 0px !important;
        }
      }
    }
  }

  &[data-type="inverse"] {
    label {
      .toggle-container {
        .toggle-background span {
          &:first-of-type {
            color: var(--color-disabled);
          }
          &:last-of-type {
            color: var(--color-primary);
          }
        }
      }
      &[data-value="true"] {
        .toggle-container {
          .toggle-background:before {
            background-color: transparent;
          }
        }
      }
      &[data-value="false"] {
        .toggle-container {
          .toggle-background:before {
            background-color: var(--red-dark);
          }
        }
      }
    }
  }

  label {
    display: flex;
    cursor: pointer;
    align-items: center;
    font-size: var(--font-size-md);
    color: var(--color-secondary);
    gap: var(--unit-md);
    &:focus,
    &:focus-within {
      button:before {
        border-color: var(--outline-active) !important;
      }
    }
    &:hover {
      button {
        &:before {
          border-color: var(--outline-hover);
        }
      }
    }
    &:active {
      button {
        &:before {
          border-color: var(--outline-active);
        }
      }
    }
    &[data-disabled="true"] {
      pointer-events: none;
      button {
        border-color: transparent !important;
        color: var(--color-disabled) !important;
        &:before {
          background-color: var(--background-primary) !important;
        }
        &:hover, &:focus, &:active {
          border-color: transparent !important;
          transform: translateY(0px) !important;
        }
      }
    }
    &[data-value="true"] {
      .toggle-container {
        .toggle-background:before {
          background-color: var(--green-main);
        }
      }
      button {
        left: 50%;
      }
    }
  }

  .toggle-container {
    display: flex;
    align-items: center;
    width: calc(var(--form-md) * 2);
    height: var(--form-md);
    position: relative;
    margin: 0px 3px;
    font-size: var(--font-size-sm);
    .toggle-background {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      &:before {
        content: '';
        display: block;
        background: var(--background-primary);
        border: 1px solid var(--outline-default);
        border-radius: 1000px;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 0;
        transition: background-color 0.1s ease-in-out;
        box-shadow: inset var(--shadow-dark-medium);
      }
      & > span {
        position: relative;
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &:first-of-type {
          color: var(--color-primary);
        }
        &:last-of-type {
          color: var(--color-disabled);
        }
      }
    }
  }

  button {
    position: absolute;
    top: 0px;
    left: 0%;
    width: 50%;
    height: 100%;
    cursor: pointer;
    padding: 0px;
    color: var(--color-primary);
    font-size: var(--font-size-md);
    border-radius: var(--radius-lg);
    outline: none;
    resize: none;
    transform: translateY(0px);
    transition:
      transform 0.1s ease-in-out,
      border-color 0.1s ease-in-out,
      left 0.1s ease-in-out;
    display: inline-flex;
    font-family: var(--font-family);
    background-color: transparent;
    border: none;
    &:before {
      content: '';
      display: block;
      background: var(--background-tertiary);
      border: 1px solid var(--outline-default);
      border-radius: 1000px;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    .special-border {
      display: none;
      background: var(--red-main);
      border-radius: 1000px;
      position: absolute;
      top: -2px;
      left: -2px;
      width: calc(100% + 6px);
      height: calc(100% + 6px);
      z-index: 0;
      overflow: hidden;
      .overflow {
        position: absolute;
        z-index: 0;
        top: -500%;
        left: -500%;
        right: -500%;
        bottom: -500%;
        animation: loading-spin 10000s linear infinite;
        background: conic-gradient(
          red, 
          orange, 
          yellow, 
          green, 
          cyan, 
          blue, 
          violet,
          red
        );
      }
    }
  }

  &[data-size="small"] {
    label {
      font-size: var(--font-size-sm);
    }
    .toggle-container {
      width: calc(var(--form-sm) * 2);
      height: var(--form-sm);
      font-size: var(--font-size-xs);
    }
    button {
      font-size: var(--font-size-sm); 
    }
  }

  &[data-size="large"] {
    label {
      font-size: var(--font-size-lg);
    }
    .toggle-container {
      width: calc(var(--form-lg) * 2);
      height: var(--form-lg);
      font-size: var(--font-size-md);
    }
    button {
      font-size: var(--font-size-xl);
    }
  }

  &[data-color="special"] {
    label {
      .toggle-container {
        margin: 0px 5px;
      }
      button {
        &:before {
          border-color: transparent;
          background-color: var(--background-primary);
        }
        .special-border {
          display: block;
        }
      }
      &:hover {
        button {
          .special-border {
            .overflow {
              animation: loading-spin 5s linear infinite;
            }
          }
        }
      }
    }
  }

  &[data-color="blurple"] {
    label {
      button {
        &:before {
          background-color: var(--blurple);
        }
      }
    }
  }

  &[data-color="green"] {
    label {
      button {
        &:before {
          background-color: var(--green-main);
        }
      }
    }
  }

  &[data-color="blue"] {
    label {
      button {
        &:before {
          background-color: var(--blue-dark);
        }
      }
    }
  }

  &[data-color="orange"] {
    label {
      button {
        &:before {
          background-color: var(--orange-dark);
        }
      }
    }
  }

  &[data-color="red"] {
    label {
      button {
        &:before {
          background-color: var(--red-dark);
        }
      }
    }
  }

}