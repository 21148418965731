[data-component="ChatBox"] {

  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;

  .scrollable {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 100%;
    padding: 0px;
    padding-bottom: calc(var(--unit-lg) + (var(--form-md) * 0.5));
  }

  .fixed-notification {
    padding: 0px var(--unit-md);
    padding-bottom: var(--unit-md);
    margin-top: calc(-1 * var(--form-md) * 0.5);
    margin-bottom: calc(var(--form-md) * 0.5);
    z-index: 2;
    .notification-content {
      background: var(--orange-dark);
      border-radius: var(--radius-lg);
      padding: var(--unit-md);
      font-size: var(--font-size-sm);
      display: flex;
      align-items: center;
      gap: var(--unit-md);
      animation: pop-in 0.2s cubic-bezier(.25, .8, .25, 1);
      .notification-message {
        padding-left: var(--unit-md);
        flex-grow: 1;
        color: var(--white);
        white-space: pre-wrap;
      }
    }
  }

  .fixed {
    display: flex;
    flex-shrink: 0;
    align-items: flex-end;
    padding: var(--unit-md);
    padding-top: 0px;
    margin-top: calc(-1 * var(--form-md) * 0.5);
    [data-component="ActionButton"] {
      margin-right: calc(-1 * var(--unit-lg));
    }
  }

  .description {
    width: 100%;
    display: flex;
    height: calc(var(--form-md) + var(--unit-lg));
    gap: var(--unit-md);
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: var(--font-size-md);
    color: var(--color-tertiary);
    padding: var(--unit-lg);
    margin-bottom: var(--unit-xl);
    position: relative;
    &:before {
      display: block;
      position: absolute;
      bottom: 0px;
      max-width: 800px;
      left: 0px;
      right: 0px;
      height: 1px;
      margin: 0 auto;
      content: '';
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        var(--outline-hover) 40%,
        var(--outline-hover) 60%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }
  
  mark {
    display: inline-block;
    font-weight: var(--font-weight-medium);
    background-color: var(--blue-dark);
    color: var(--white);
    padding: var(--unit) var(--unit-sm);
    opacity: 0.8;
    border-radius: var(--radius);
    cursor: pointer;
    margin: var(--unit-sm);
    transition: opacity ease-in-out 0.1s;
    &:hover {
      opacity: 1.0;
    }
  }

}