[data-component="ImageUploader"] {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  input[type="file"] {
    display: none;
  }
  &:not([data-loading="true"]) {
    .loading-container {
      display: none;
    }
  }
  &[data-loading="true"] {
    pointer-events: none;
    .image-actions {
      display: none;
    }
  }
  .loading-container {
    position: absolute;
    display: flex;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    background-color: var(--background-primary);
    color: var(--color-disabled);
    svg {
      width: var(--unit-xl);
      height: var(--unit-xl);
      animation: loading-spin 1.5s ease-in-out infinite;
    }
  }
  .image-actions {
    display: flex;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    margin: var(--unit-sm);
    .reset, .close, .upload {
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: var(--unit-sm);
      background-color: var(--background-primary);
      cursor: pointer;
      svg {
        width: var(--unit-lg);
        height: var(--unit-lg);
      }
      &:hover {
        background-color: var(--red-main);
      }
    }
    .reset:hover {
      background-color: var(--blue-dark);
    }
    .upload:hover {
      background-color: var(--green-main);
    }
    &.bottom {
      top: auto;
      bottom: 0px;
    }
  }
  img, div.img {
    width: 96px;
    height: 96px;
    display: block;
    border-radius: var(--radius-lg);
    background-color: var(--background-primary);
    background-size: cover;
    background-repeat: no-repeat;
    font-size: var(--font-size-sm);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    object-fit: cover;
    object-position: center center;
    background-position: center center;
    color: var(--color-disabled);
    user-select: none;
    cursor: pointer;
    border-radius: 1000px;
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &[data-type="inline"] {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    img, div.img {
      width: 100%;
      height: 100%;
      border-radius: var(--radius-lg);
      background-color: transparent;
    }
    &:not([data-is-mobile="true"]) {
      .image-actions {
        display: none;
      }
    }
    .image-actions {
      margin: var(--unit);
      .reset, .close, .upload {
        background-color: var(--background-input);
        &:hover {
          background-color: var(--background-tertiary);
        }
        svg {
          width: calc(var(--unit-md) * 1.5);
          height: calc(var(--unit-md) * 1.5);
        }
      }
    }
    &:hover {
      .image-actions {
        display: flex;
      }
    }
  }
}