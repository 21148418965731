[data-component="ContentButton"] {
  font-weight: var(--font-weight-normal);
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  border-radius: var(--radius-lg);
  font-size: var(--font-size-sm);
  cursor: pointer;
  color: var(--color-primary);
  user-select: none;
  transition: background 0.1s ease-in-out,
    color 0.1s ease-in-out,
    opacity 0.1s ease-in-out;
  & > a, & > span {
    padding: var(--unit-md) var(--unit-lg);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--unit-md);
    &:link, &:visited, &:hover, &:active {
      text-decoration: none;
      color: inherit;
    }
  }
  &[data-size="small"] {
    & > a, & > span {
      padding: var(--unit-sm) var(--unit-md);
    }
    &[data-icon-only="true"] {
      & > a, & > span {
        padding: calc(var(--unit-sm) * 1.5);
      }
    }
    .icon {
      width: calc(var(--unit-md) + var(--unit-sm));
      height: calc(var(--unit-md) + var(--unit-sm));
    }
  }
  &[data-icon-only="true"] {
    & > a, & > span {
      padding: var(--unit-md);
    }
  }
  &[data-loading="true"],
  &[data-disabled="true"] {
    pointer-events: none;
  }
  &[data-darken="true"] {
    background: rgba(0, 0, 0, 0.4);
    &:hover {
      background: rgba(0, 0, 0, 0.6);
    }
  }
  .button-content {
    line-height: var(--unit-lg);
  }
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .icon {
    width: var(--unit-lg);
    height: var(--unit-lg);
    stroke-width: 1;
    color: var(--color-primary);
    opacity: 0.6;
    transition: opacity 0.1s ease-in-out, color 0.1s ease-in-out,
      fill 0.1s ease-in-out;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.4);
    .icon {
      opacity: 1;
    }
  }
  &[data-icon-styling="fill"] {
    .icon {
      fill: var(--color-primary);
    }
  }
  &[data-icon-styling="fill-shrink"] {
    .icon {
      fill: var(--color-primary);
      transform: scale(0.8);
    }
  }
  &[data-icon-styling="off"] {
    .icon-container {
      opacity: 0.6;
      &:before {
        position: absolute;
        content: '';
        display: block;
        width: 140%;
        height: 2px;
        background: var(--color-primary);
        border: 2px solid var(--background-primary);
        border-radius: 1000px;
        transform: rotate(45deg);
        z-index: 2;
      }
    }
    &[data-color="grey"] {
      .icon-container:before {
        border-color: var(--grey-main);
      }
    }
    &[data-color="green"] {
      .icon-container:before {
        border-color: var(--green-main);
      }
    }
    &[data-disabled="true"] {
      .icon-container:before {
        border-color: var(--background-primary);
      }
    }
  }
  &[data-styling="chat"] {
    border-radius: 1000px;
    background: var(--background-primary);
    height: calc(var(--form-md) - var(--unit-lg));
    margin: var(--unit-md) 0px;
    .icon.has-content {
      margin: 0px calc(-1 * var(--unit-sm));
    }
  }
  &[data-disabled="true"] {
    background: var(--background-primary) !important;
    color: var(--color-disabled) !important;
    .icon {
      color: var(--color-disabled) !important;
    }
    &[data-icon-styling="fill"],
    &[data-icon-styling="fill-shrink"] {
      .icon {
        fill: var(--color-disabled) !important;
      }
    }
  }
  &[data-color]:not([data-color="default"]) {
    color: var(--white);
    .icon {
      opacity: 1;
      stroke-width: 2;
    }
  }
  &[data-color][data-loading="true"] {
    background: var(--background-primary);
    color: var(--color-disabled);
    .icon {
      color: var(--color-disabled);
    }
  }
  &[data-color="orange"] {
    background: var(--orange-dark);
    &:hover, &[data-active="true"] {
      background: var(--orange-main);
    }
  }
  &[data-color="green"] {
    background: var(--green-dark);
    &:hover, &[data-active="true"] {
      background: var(--green-main);
    }
  }
  &[data-color="blue"] {
    background: var(--blue-dark);
    &:hover, &[data-active="true"] {
      background: var(--blue-main);
    }
  }
  &[data-color="purple"] {
    background: var(--purple-dark);
    &:hover, &[data-active="true"] {
      background: var(--purple-main);
    }
  }
  &[data-color="red"] {
    background: var(--red-dark);
    &:hover, &[data-active="true"] {
      background: var(--red-main);
    }
  }
  &[data-color="grey"] {
    background: var(--background-input);
    &:hover, &[data-active="true"] {
      background: var(--background-secondary);
    }
  }
  &[data-styling="condensed"] {
    & > a, & > span {
      padding: var(--unit-md);
    }
  }
  strong {
    font-weight: var(--font-weight-medium);
  }
}

[data-component="ContentButtonGroup"] {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--radius-lg);
  overflow: auto;
  max-width: 100%;
  & > [data-component="ContentButton"]:not(:first-child) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 1px solid var(--outline-minimal);
  }
  & > [data-component="ContentButton"]:not(:last-child) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

@keyframes shake {
  0% {
    transform: translateX(-3px);
  }
  50% {
    transform: translateX(3px);
  }
  100% {
    transform: translateX(-3px);
  }
}

[data-component="ContentButtonGroup"].shake,
[data-component="ContentButton"].shake {
  animation: shake 0.1s linear infinite;
}
