[data-component="IndexPage"] {

  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-image: url('../../public/assets/grid-tile.svg');
    background-size: 128px;
    background-position: 50% 50%;
    /* Set up the mask */
    -webkit-mask-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 25%, rgba(0, 0, 0, 1) 100%);
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: 100% 100%;
    /* For non-webkit browsers */
    mask-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 25%, rgba(0, 0, 0, 1) 100%);
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
    opacity: 0.05;
    z-index: 0;
  }

  .hero-container {
    padding: var(--unit-xl);
    padding-top: calc(var(--unit-xl) * 4);
    padding-bottom: calc(var(--unit-xl) * 4);
    background: linear-gradient(180deg, var(--background-primary) 0%, rgba(0, 0, 0, 0) 80vh);
    z-index: 1;
  }

  .hero {
    position: relative;
    display: flex;
    min-height: calc(100vh - var(--unit-xl) * 8);
    width: 100%;
    max-width: var(--screen-lg);
    margin: 0px auto;
    padding: 0px calc(var(--unit-xl) * 1.5);
    margin-top: calc(-1 * var(--unit-xl) * 1.5);
    align-items: center;
    gap: calc(var(--unit-xl) * 1.5);
    justify-content: center;
    .hero-demo {
      position: relative;
      flex-grow: 1;
      width: calc(50% - var(--unit-xl) * 3 / 2);
      max-width: 500px;
      background: var(--background-secondary);
      border-radius: var(--radius-lg);
      [data-component="ChatBox"] {
        box-shadow: var(--shadow-dark-large);
      }
    }
    .hero-info {
      position: relative;
      flex-grow: 1;
      width: calc(50% - var(--unit-xl) * 3 / 2);
      max-width: 600px;
      .hero-image {
        display: flex;
        align-items: flex-start;
        img {
          width: 96px;
          height: 96px;
          margin-left: -9px;
        }
      }
      .hero-title {
        font-family: var(--font-family-serif);
        font-style: italic;
        font-size: var(--font-size-xxxl);
        font-weight: var(--font-weight-medium);
      }
      .hero-desc {
        padding: var(--unit-lg) 0px;
        color: var(--color-secondary);
        font-size: var(--font-size-xl);
      }
      .hero-cta {
        position: relative;
        margin-top: var(--unit-lg);
        .hero-cta-explainer {
          margin-top: var(--unit-md);
          color: var(--color-secondary);
          font-size: var(--font-size-md);
          display: flex;
          align-items: center;
          svg {
            width: 18px;
            height: 18px;
            padding: var(--unit);
            margin: 0px var(--unit-sm);
            border-radius: var(--radius);
            &.discord-logo {
              path {
                fill: var(--blurple);
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .hero-demo {
      display: none;
    }
    .hero-container {
      padding-left: 0px;
      padding-right: 0px;
      .hero {
        padding-left: var(--unit-xl);
        padding-right: var(--unit-xl);
      }
    }
  }

}