[data-component="InfoBox"] {

  display: inline-flex;
  align-items: flex-start;
  border-radius: var(--radius-lg);
  padding: var(--unit-lg);
  margin-bottom: var(--unit-lg);
  background: var(--background-input);
  border: 1px solid var(--background-secondary);
  color: var(--color-secondary);
  gap: var(--unit-lg);
  max-width: 100%;
  overflow: hidden;

  & > svg {
    flex-shrink: 0;
    width: var(--unit-lg);
    height: var(--unit-lg);
    color: var(--color-disabled);
    margin-top: calc(var(--unit-sm));
    color: var(--blue-main);
  }

  & > span {
    margin: calc(-1 * var(--unit)) 0px;
    padding: var(--unit) 0px;
    overflow: hidden;
    h1 {
      display: inline-flex;
      font-size: var(--font-size-lg);
      font-weight: var(--font-weight-medium);
      margin: 0px var(--unit-lg);
      padding: var(--unit-lg) 0px;
      color: var(--color-primary);
      align-items: center;
      gap: var(--unit-md);
      border-bottom: 1px solid var(--outline-minimal);
      svg {
        width: var(--unit-xl);
        height: var(--unit-xl);
        color: var(--color-tertiary);
      }
    }
    strong {
      font-weight: var(--font-weight-medium);
      color: var(--color-primary);
    }
    span {
      line-height: var(--line-height-lg);
    }
    code {
      display: inline-flex;
      font-size: var(--font-size-sm);
      font-family: var(--font-family-monospace);
      color: var(--color-primary);
      border: 1px solid var(--outline-default);
      background: var(--background-primary);
      padding: var(--unit) var(--unit-md);
      border-radius: var(--radius);
      word-wrap: none;
      overflow: auto;
      max-width: 100%;
    }
  }

}