[data-component="PackageDetailsPage"] {

  margin: 0px var(--unit-lg);
  margin-bottom: calc(var(--unit-xl) * 2);

  .packages-list {
    max-width: var(--screen-sm);
    margin: 0px auto;
    & > [data-component] {
      margin: var(--unit-md) 0px;
    }
  }

  .package-details {
    display: block;
  }

  .package-endpoints-header,
  .package-files-header,
  .package-single-file-header {
    margin-top: var(--unit-xl);
    padding: var(--unit-lg);
    display: block;
    align-items: center;
    font-weight: var(--font-weight-medium);
    & > span.middot {
      margin: 0px var(--unit-md);
    }
    & > span.container {
      display: inline-flex;
      align-items: center;
      gap: var(--unit-md);
    }
    span.label {
      font-weight: var(--font-weight-normal);
      color: var(--color-tertiary);
    }
    svg {
      width: calc(var(--unit-md) + var(--unit-sm));
      height: calc(var(--unit-md) + var(--unit-sm));
      stroke-width: 1;
      opacity: 0.6;
      margin-right: var(--unit-md);
    }
    a {
      display: inline-flex;
      align-items: center;
      gap: var(--unit-sm);
      svg {
        width: calc(var(--unit-md) + var(--unit-sm));
        height: calc(var(--unit-md) + var(--unit-sm));
        stroke-width: 1;
      }
    }
  }

  .package-endpoints {
    display: flex;
    flex-direction: column;
    background: var(--background-input);
    border-radius: var(--radius-lg);
  }

  .package-files {
    padding: var(--unit-sm) 0px;
    border-radius: var(--radius-lg);
    background: var(--background-input);
    overflow: hidden;
    table {
      width: 100%;
      padding: var(--unit-md);
      border-collapse: collapse;
      table-layout: fixed;
    }
    tr.selected {
      a {
        pointer-events: none;
        text-decoration: none !important;
        color: var(--color-primary) !important;
      }
    }
    td {
      width: auto;
      padding: var(--unit-sm) 0px;
      word-break: break-word;
      overflow-wrap: anywhere;
    }
    td.icon {
      width: var(--form-md);
      text-align: center;
      flex-shrink: 0;
      svg {
        opacity: 0.4;
        width: calc(var(--unit-md) + var(--unit-sm));
        height: calc(var(--unit-md) + var(--unit-sm));
      }
    }
    td.size {
      text-align: right;
      color: var(--color-disabled);
      padding: 0px var(--unit-lg);
      width: 64px;
      flex-shrink: 0;
      white-space: nowrap;
    }
    td.package-files-empty {
      padding: var(--unit-lg);
      color: var(--color-disabled);
    }
  }

  .package-filename {
    background: none;
  }

  .package-single-file {
    margin-top: var(--unit-xl);
    .package-file-details {
      position: relative;
      border-radius: var(--radius-lg);
      overflow: hidden;
    }
  }

  .package-keychain-keys {
    padding: var(--unit-md);
    border-radius: var(--radius-lg);
    background: var(--background-input);
    display: flex;
    flex-direction: column;
    gap: var(--unit-md);
    .package-keychain-key {
      display: flex;
      align-items: center;
      gap: var(--unit-md);
      svg {
        width: var(--unit-lg);
        height: var(--unit-lg);
        color: var(--color-disabled);
        margin: 0px var(--unit-md);
      }
      .key-entry {
        display: flex;
        flex-direction: column;
        .key-name {
          font-weight: var(--font-weight-medium);
        }
        .key-description {
          color: var(--color-tertiary);
          font-size: var(--font-size-sm);
        }
      }
    }
  }

  .package-danger-header {
    @extend .package-files-header;
    margin-top: calc(var(--unit-xl) * 2);
    svg {
      color: var(--red-main);
      stroke-width: 3 !important;
    }
  }

  .package-danger-zone {
    padding: var(--unit-md);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--unit-lg);
    border: 1px solid var(--background-input);
    border-radius: var(--radius-lg);

    .danger-action {

      padding: var(--unit-lg);
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: var(--unit-md);
      width: 100%;
      flex-wrap: wrap;

      .danger-action-description {
        .title {
          font-weight: var(--font-weight-medium);
          margin-bottom: var(--unit-sm);
        }
        .description {
          color: var(--color-tertiary);
        }
      }
    }
  }

}

[data-component="PackageEndpoint"] {
  &:not(:first-child) {
    border-top: 1px solid var(--background-input);
  }
  .package-endpoint-header {
    padding: var(--unit-md) var(--unit-lg);
    display: flex;
    align-items: center;
    gap: var(--unit-md);
    background: transparent;
    transition: background 0.1s ease-in-out;
    .package-endpoint-header-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.4;
      margin-right: var(--unit-md);
      svg {
        stroke-width: 1;
        width: var(--unit-lg);
        height: var(--unit-lg);
      }
    }
    .package-endpoint-name {
      display: flex;
      flex-direction: column;
      .package-endpoint-title {
        font-size: var(--font-size-md);
        font-weight: var(--font-weight-medium);
      }
      .package-endpoint-route {
        display: flex;
        align-items: center;
        color: var(--color-tertiary);
        font-size: var(--font-size-sm);
        gap: var(--unit-sm);
        .package-endpoint-method {
          display: flex;
          align-items: center;
          flex-shrink: 0;
          & > span {
            display: flex;
            align-items: center;
          }
        }
        .route-name {
          flex-grow: 1;
        }
      }
    }
  }
}

[data-component="PackageEndpointDetails"] {
  .package-single-endpoint {
    color: var(--color-tertiary);
    border-radius: var(--radius-lg);
    background: var(--background-input);
    padding: var(--unit-lg);
    display: flex;
    flex-direction: column;
    color: var(--color-primary);

    .description {
      &.empty {
        color: var(--color-disabled);
      }
      .tool-description-line:first-child {
        font-weight: var(--font-weight-medium);
      }
      .tool-description-line:not(:first-child) {
        color: var(--color-tertiary);
      }
    }

    .heading {
      margin-bottom: var(--unit-lg);
      font-weight: var(--font-weight-medium);
    }

    code {
      font-family: var(--font-family-monospace);
      font-size: var(--font-size-sm);
      background: var(--background-primary);
      padding: var(--unit) var(--unit-sm);
      margin: 0px var(--unit-sm);
      border-radius: var(--radius);
    }

    .parameters {
      &.empty {
        color: var(--color-disabled);
      }
    }

    .tool-argument {
      color: var(--color-tertiary);
      &:not(:last-child) {  
        margin-bottom: var(--unit-lg);
      }
      .tool-argument-row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding-left: var(--unit-lg);
        &.sub {
          padding-left: var(--unit-xl);
        }
        .tool-argument-name {
          color: var(--color-primary);
          font-weight: var(--font-weight-medium);
        }
        .tool-argument-required {
          color: var(--orange-main);
        }
        .middot {
          margin: 0px var(--unit-md);
          opacity: 0.6;
          &:first-child {
            display: none;
          }
        }
      }
    }
  }
}

[data-component="PackageEndpointDeveloperNotes"] {

  h1 {
    padding: 0px;
    margin: 0px;
    margin-top: calc(var(--unit-xl) * 2);
    margin-bottom: calc(-1 * var(--unit-xl));
    padding: var(--unit-lg);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-lg);
    color: var(--color-primary);
    border-bottom: 1px solid var(--background-input);
    display: flex;
    align-items: center;
    gap: var(--unit-md);
    svg {
      color: var(--color-disabled);
      width: var(--unit-lg);
      height: var(--unit-lg);
    }
  }

}